// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: User projects page

import React, { useContext } from "react";
import { Button } from "@mui/material"
import NewProject from "#assets/new_project.png"
import FadeInSection from "#components/FadeSection";
import { getMapData, getUserMaps, getUserApiKey } from "#libs/apis/backend";
import {
  useAuthUser,
} from "react-auth-kit";

import "#styles/pages/ProjectsPage"
import { useNavigate } from "react-router-dom";
import {ProjectContext} from "#components/Contexts";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProjectBar from "#components/ProjectBar";
import { EDIT_MAP_PAGE } from "App";

const MAX_SHADINGS = 5;

/**
 * Projects page
 * @returns  React element
 */
function ProjectsPage(): JSX.Element {
	const [projects, setProjects] = React.useState<any>([]);
	const [hasFetched, setFetched] = React.useState(false)

	// Navigation helpers
	const navigate = useNavigate();
	const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);
	
	// Get user auth key
	const auth = useAuthUser();
	const authData = auth();
	const authKey = authData?.authKey || "";
	
	// Async function to retrieve a list of the user's projects from the database
	const fetchProjects = async () => {
		// console.log(authData)
		const userProjects = await getUserMaps(authKey);
		const apiKey = await getUserApiKey(authKey);

		if (userProjects.length) {
			const projectData = userProjects.map((project: any) => {
				return {
					id: project.mapId,
					name: project.mapName,
					previewImg: `data:image/png;base64,${project.mapThumbnail}`,
					apiKey: apiKey,		// FIXME: Hack to get api key of user in project context
				};
			});
			setProjects(projectData);
			setFetched(true);
		}
	}

	// If no projects are present on page load, retrieve the projects
	if (!hasFetched) fetchProjects();

	// Create a list of cards from the project list
	const cards = [<AddProjectCard />].concat(projects.map((project: any) => 
		<ProjectCard id={project.id} name={project.name} previewImg={project.previewImg} apiKey={project.apiKey}/>
	))

	// Place the cards into the card rows
	const cardRows = []
	for (let i = 0; i < cards.length; i += 2) {
		cardRows.push(
			<CardRow>
				{cards[i]}
				{i < cards.length && cards[i + 1]}
			</CardRow>
		);
	}

	return (
		<ProjectBar>
			<div className="projects-page__layout">
				<section className="projects-page__content">
					<Button 
						children="Back"
						onClick={navigateToEditPage} 
						startIcon={<KeyboardBackspaceIcon />}
						sx={{ 'justify-content': "left", maxWidth: "80px" }} 
					/>
					<section className="projects__header">
						<h4>{"My shadings"}</h4>
						<h4>Total: {projects.length}</h4>
					</section>
					<div className="projects__shadow--top" />
					<div className="projects__content">{cardRows}</div>
					<div className="projects__shadow--bottom" />
				</section>
			</div>
		</ProjectBar>
	)
}
/**
 * Cards row
 * @param props 
 * @returns  
 */
function CardRow(props: {children: React.ReactNode}) {
	return (
		<FadeInSection>
			<section className="projects__content-row">
				{props.children}
			</section>
		</FadeInSection>
	)
}

/**
 * Base card used for projects
 * @returns 
 */
function ProjectCardBase(props: {children: React.ReactNode, onClick?: () => void}) {
	return (
		<Button className="project-card__base" onClick={props.onClick}>
			{props.children}
		</Button>
	)
}

/**
 * Card for adding a new project.
 * @returns 
 */
function AddProjectCard() {
	const navigate = useNavigate();
	const project = useContext(ProjectContext);

	const handleNewProject = () => {
		project.reset();
		navigate(EDIT_MAP_PAGE)
	};

	return (
		<ProjectCardBase onClick={handleNewProject}>
			<img src={NewProject} alt="New Shading" style={{width: 280, height: 280}}/>
			<h6>New Shading</h6>
		</ProjectCardBase>
	);
}

/***
 * Card for displaying and selecting an existing project
 */
function ProjectCard({id, name, previewImg, apiKey}: {id: string, name: string, previewImg: string, apiKey: string}) {
	const projectName = name;
	const navigate = useNavigate();
	const project = useContext(ProjectContext);

	const handleSelected = async () => {
		const res = await getMapData(id)
		project.reset()

		project.mapId = id;
		project._bounds = {
			north: res.mapNorth,
			south: res.mapSouth,
			east: res.mapEast,
			west: res.mapWest,
		};
		project.settings = {
			modelNo: res.mapModelNo, 
			lightRotation: res.mapAngle,
			generalization: res.mapMacro,
			generalizationDetails: res.mapMicro,
			aerialPerspective: res.mapAP,
			slopeDarkness: res.mapContrast,
			elevationRangeMin: res.mapTMin,
			elevationRangeMax: res.mapTMax,
			flatAreaAmount: res.mapFlatAmount,
			flatAreaSize: res.mapFlatSize,
		}; 
		project.elevationModel = res.mapEModel;
		project.projection = res.mapProj;
		project.apiKey = apiKey;
		project.projectName = res.projectName;
			
		navigate(EDIT_MAP_PAGE);
	}

	return (
		<ProjectCardBase onClick={handleSelected}>
			<img src={previewImg} alt={projectName} style={{width: 280, height: 280}}/>
			<h6>{projectName}</h6>
		</ProjectCardBase>
	);
}

export default ProjectsPage;