// Written by: FIT3162 CS Team 1
// Last modified: 1/06/24
// Title: Main app index file

import React from 'react';
import ReactDOM from 'react-dom/client';
import '#styles/index';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from "react-auth-kit";
import { FlagsProvider } from 'react-feature-flags';
import { Props } from '#libs/types';


// Get the root HTML element
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const buildFlags = [
  { name: 'enableAuthentication', isActive: process.env.REACT_APP_GOOGLE_CLIENT_ENABLED?.toLowerCase() === 'true' || false },
  { name: 'enableStripe', isActive: process.env.REACT_APP_STRIPE_ENABLED?.toLowerCase() === 'true' || false }
];

// Render the app
root.render(
  <FlagsProvider value={buildFlags}>
    <AuthProvider
      authType={'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
        <App />
      </GoogleOAuthProvider>
    </AuthProvider>
  </FlagsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
