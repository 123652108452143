// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Account menu dropdown

/**
 * From
 * https://mui.com/material-ui/react-menu/
 */
import * as React from "react";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { 
  Settings as SettingsIcon, 
  Logout as LogoutIcon,
  Payment as PaymentIcon,
  BurstModeSharp as BurstModeSharpIcon,
  AddPhotoAlternateOutlined as NewIcon
} from "@mui/icons-material";
import {
  useAuthUser,
  useIsAuthenticated,
  useSignIn,
  useSignOut,
} from "react-auth-kit";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { ProjectContext } from "#components/Contexts";
import Project from "#libs/Project";

import "#styles/components/ProjectBar";
import "#styles/glass"
import { EDIT_MAP_PAGE, PAYMENT_PAGE, PROJECTS_PAGE, USER_PROFILE_PAGE } from "App";

/**
 * Account menu
 */
export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const project = React.useContext<Project>(ProjectContext);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Callback function from logging out
  const navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const authData = auth();
  const isAuthenticated = useIsAuthenticated();

  // Callback function from logging in
  function handleCallbackResponse(response: any) {
    const decoded = jwt_decode<JwtPayload>(response.credential); // Decode the values from the jwt response

    if (
      "email" in decoded &&
      "name" in decoded &&
      "sub" in decoded &&
      decoded.sub) {

      signIn({
        token: response.credential,  // Sign in token, not user tokens
        expiresIn: 1440,
        tokenType: "Bearer",
        authState: {
          authKey: decoded.sub,
          email: decoded.email,
          name: decoded.name,
        },
      })
      navigate(EDIT_MAP_PAGE);
    } else {
      //Throw error
      console.error("Error: not all user details are present")
    }
  }
  function handleSignOut() {
    signOut();
    googleLogout();
    //navigate("/login");  // Should redirect?, needed to prevent max update
    handleClose();
  }

  // Navigation button callback functions
  const handleCredits = () => navigate(PAYMENT_PAGE);
  const handleProjects = () => navigate(PROJECTS_PAGE);
  const handleProfile = () => navigate(USER_PROFILE_PAGE);

  function handleNewProject() {
    project.reset();
    navigate(EDIT_MAP_PAGE);
  }

  const AccountMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          display: "flex",
          flexDirection: "row-reverse"
        }}
        slotProps={{
          paper: {
            // className: "glass--dark",
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
      >
        {!isAuthenticated() ? (
          <MenuItem onClick={handleClose} sx={{flexDirection: 'reverse'}}>
            {/* If the user is not logged in, show the Google login button */}
            <GoogleLogin
              onSuccess={handleCallbackResponse}
              onError={() => {
                console.log("Login Failed");
              }}
              useOneTap
              theme="filled_blue"
              shape="circle"
            />
          </MenuItem>
        ) : (
          [
            <MenuItem key="account" onClick={handleProfile}>
              {/* Otherwise if logged in, show full navigation panel */}
              <Avatar />
              My account
            </MenuItem>,
            <Divider key="divider" />,
            <MenuItem key="shadings" onClick={handleProjects}>
              <ListItemIcon>
                <BurstModeSharpIcon fontSize="small" />
              </ListItemIcon>
              Shadings
            </MenuItem>,
            <MenuItem key="credits" onClick={handleCredits}>
              <ListItemIcon>
                <PaymentIcon fontSize="small" />
              </ListItemIcon>
              Credits
            </MenuItem>,
            <MenuItem key="logout" onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>,
          ]
        )}
      </Menu>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
      <Tooltip title="Account">
        {/* User icon */}
        <IconButton
          onClick={handleClick}
          size="small"
          // className="rounded"
          sx={{
            width: "46px",
            height: "46px",
            borderRadius: 10,
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>{authData?.name[0]}</Avatar>
        </IconButton>
      </Tooltip>
      {/* Account menu dropdown */}
      <AccountMenu />
    </Box>
  );
}
