/**
 * Digital Elevation Model drop down selction for OpenTopography.
 */
import { ElevationModel } from "#libs/Project";
import { InputLabel, MenuItem, TextField } from "@mui/material";

const DEM_MENU_ITEMS = new Map<ElevationModel, string>([
	[ElevationModel.AW3D30, "ALOS 30 m"],
	[ElevationModel.NASADEM, "NASADEM 30 m"],
	[ElevationModel.SRTMGL1, "SRTM 30 m"],
	[ElevationModel.SRTMGL3, "SRTM 90 m"],
]);


// Digital elevation models
interface DEMDropDownProps {
	elevationModel: ElevationModel,
	onChange: (arg0: ElevationModel) => void,
	defaultValue?: ElevationModel,
}

function DEMDropdown({
	elevationModel,
	onChange,
	defaultValue = ElevationModel.AW3D30
}: DEMDropDownProps
) {
	const demMenuItems = Array.from(DEM_MENU_ITEMS).map(([dem, text]) => {
		return (
			<MenuItem value={dem}>{text}</MenuItem>
		);
	});

	return (
		<TextField
			select
			inputProps={{ id: 'elevationModel' }}
			defaultValue={defaultValue}
			value={elevationModel}
			onChange={(e) => onChange(e.target.value as ElevationModel)}
			fullWidth
		>
			<InputLabel sx={{paddingX: 2, opacity: 0.5}}>Elevation Model</InputLabel>
			{demMenuItems}
		</TextField>
	);
}

export default DEMDropdown;