// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: App element

import React from 'react'; // Import React
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { RequireAuth as ReactRequireAuth } from 'react-auth-kit';
import { Flags } from 'react-feature-flags';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import ThemeController from "./components/ThemeController";
import DownloadMapPage from "./pages/DownloadMap";
import EditMapPage from "./pages/EditMapPage";
import PaymentPage from "./pages/PaymentPage";
import ProjectsPage from './pages/ProjectsPage';
import UserProfilePage from "./pages/UserProfile";

import { getUserCredits } from "#libs/apis/backend";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { CreditsContext } from "#components/Contexts";

/**
 * Constants for page links
 */
const EDIT_MAP_PAGE = "/";
const DOWNLOAD_MAP_PAGE = "/download-map";
const PAYMENT_PAGE = "/credits";
const USER_PROFILE_PAGE = "/profile";
const PROJECTS_PAGE = "/shadings";


/**
 * Safeguard the children with authentication
*/
function RequireAuth({children}: { children: JSX.Element}) {
  return (
    <Flags authorizedFlags={['enableAuthentication']}
      renderOn={() => <ReactRequireAuth loginPath={'/'}>{children}</ReactRequireAuth>}
      renderOff={() => children}
    />
  );
}


// Define the app page routes
function App() {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const isAuthenticated = useIsAuthenticated();
  
  const [userCredits, setUserCredits] = React.useState(0);
  const updatePageCredits = async () => {
    if (isAuthenticated()) {
      const credits_amount = await getUserCredits(authKey);
      setUserCredits(credits_amount);
    }
    else {
      setUserCredits(0);
    }
  };

    // Update the credit balance display on load
    React.useEffect(() => {
      console.log("Updating credits")
      updatePageCredits()
        .catch(console.error)
    }, [auth, userCredits]);

  return (
    <ThemeController>
      <CreditsContext.Provider value={{userCredits, updatePageCredits}}>   
        <Router>
          <Routes>
            <Route path={EDIT_MAP_PAGE} element={<EditMapPage/>} />
            <Route path={DOWNLOAD_MAP_PAGE} element={<RequireAuth><DownloadMapPage/></RequireAuth>} />
            <Route path={PAYMENT_PAGE} element={<RequireAuth><PaymentPage/></RequireAuth>} />
            <Route path={USER_PROFILE_PAGE} element={<RequireAuth><UserProfilePage/></RequireAuth>} />
            <Route path={PROJECTS_PAGE} element={<RequireAuth><ProjectsPage/></RequireAuth>} />
          </Routes>
        </Router>
      </CreditsContext.Provider>
    </ThemeController>
  );
}

export default App;
export { 
  EDIT_MAP_PAGE, 
  DOWNLOAD_MAP_PAGE,
  PAYMENT_PAGE, 
  USER_PROFILE_PAGE,
  PROJECTS_PAGE,
}