// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit page render settings panel

import { ProjectContext } from "#components/Contexts";
import createRipple from "#libs/styling/ripple";
import { ProcessingSettings } from "#libs/types";
import CircularSlider from "@fseehawer/react-circular-slider";
import {
  HelpOutline as HelpOutlineIcon,
  LensOutlined as LensOutlinedIcon,
  LensTwoTone as LensTwoToneIcon,
  LightModeRounded as LightModeRoundedIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import {
  Button,
  Icon,
  InputAdornment,
  Slider,
  Tooltip
} from "@mui/material";
import React from "react";
import AttributeContainer, { AttributeInput } from "./AttributeContainer";
// NN model icons
import alpineTerrain from "#assets/Eduard Icons/Range sliders/alpine 1x.png";
import flatTerrain from "#assets/Eduard Icons/Range sliders/flat 1x.png";
import largeScale from "#assets/Eduard Icons/styles/large-scale-2x.png";
import mediumScale from "#assets/Eduard Icons/styles/medium-scale-2x.png";
import smallScale from "#assets/Eduard Icons/styles/small-scale-2x.png";
import { NumberInput } from "#components/NumberInput";
import "#styles/components/MapProperties";
import "#styles/pages/EditMapPage";

/**
 * Neural network model select buttons
 * @param {
 *   setParams: callback,
 * } 
 */
function NeuralNetworkControl({
  setParams: callback,
}: {
  setParams: (f: number) => void;
}) {
  const allModels = {
    large: largeScale,
    medium: mediumScale,
    small: smallScale,
  };
  const project = React.useContext(ProjectContext);
  const [model, setModel] = React.useState(project.settings.modelNo);
  React.useEffect(() => {
    callback(model)
    project.settings.modelNo = model;
  }, [model]);

  // Create buttons
  const buttons = Object.entries(allModels).map(([name, icon], index) => {
    const modelNo = index + 1;
    const isSelected = modelNo === model;
    return (
      <Button
        key={name}
        color={isSelected ? "primary" : "secondary"}
        variant="outlined"
        className="model-button"
        onClick={() => setModel(modelNo)}
        sx={{ 
          border: `${isSelected ? 2 : 1}px solid`,
          height: 60,
          width: 60,
        }}
      >
        <img style={{width: 55, height: 55}}src={icon} alt={name} />
      </Button>
    );
  });

  return (
    <AttributeContainer
      description="Neural network used to create the shaded relief image."  
      noDivider
    >
      <span style={{
        translate: "0px -20px", 
        marginRight: "16px",
        height: "50px",
        display: "flex",
        justifyContent: "space-around",
      }}>{buttons}</span>
    </AttributeContainer>
  );
}

/**
 * Ilumination angle control circular slider
 * @param {
 *   setParams: callback,
 * } 
 */
function IluminationControl({
  setParams: callback,
}: {
  setParams: (f: number) => void;
}) {
  const [MIN_VALUE, MAX_VALUE] = [0, 359];
  const ANTI_CLOCKWISE = -1;

  const project = React.useContext(ProjectContext);
  const [lightRotation, setLightRotation] = React.useState(
    project.settings.lightRotation
  );
  
  // Handle value change
  const handleChange = (event: any, value: number | null) => {
    // const inputVal = parseInt(event.target.value);
    const inputVal = (value !== null) ? value : lightRotation;
    if (inputVal > MAX_VALUE) {
      setLightRotation(MIN_VALUE);
    } else if (inputVal < MIN_VALUE) {
      setLightRotation(MAX_VALUE);
    } else {
      setLightRotation(inputVal);
    }
  };
  const resetDial = () => setLightRotation(MIN_VALUE);

  React.useEffect(() => {
    callback(lightRotation);
    project.settings.lightRotation = lightRotation;
  }, [lightRotation]);

  return (
    <AttributeContainer
      paramName="Ilumination"
      description="The horizontal light direction."
    >
      <div style={{ display: "grid", gridTemplateColumns: "25% 1fr 80px" }}>
        <div className="circle-slider" style={{ gridColumn: 2}}>
          <LensTwoToneIcon
            onClick={resetDial}
            className="circle-slider__center-icon clickable"
            fontSize="small"
          />
          {/* Circular slider: https://github.com/fseehawer/react-circular-slider */}
          <CircularSlider
            dataIndex={lightRotation}
            onChange={(val: number) => setLightRotation(val)}
            width={65}
            trackSize={4}
            progressSize={6}
            knobSize={25}
            knobPosition={135}
            direction={ANTI_CLOCKWISE}
            trackDraggable
            hideLabelValue
            knobColor="var(--primary-color)"
            trackColor="rgba(var(--primary-color-raw), 0.5)"
            progressColorFrom="var(--primary-color)"
            progressColorTo="var(--primary-color)"
          >
            <LensOutlinedIcon className="circle-slider__knob-icon" />
            <LightModeRoundedIcon
              className="circle-slider__knob-icon"
              x="5px"
              y="5px"
              width="15px"
              height="15px"
            />
          </CircularSlider>
        </div>
        <div className="input-box" style={{ gridColumn: 3}}>
          <NumberInput
            value={lightRotation}
            min={MIN_VALUE - 1}
            max={MAX_VALUE + 1}
            step={1}
            onChange={(e, val) => handleChange(e, val)}
            endAdornment={
              <InputAdornment 
                children="°"
                position="end" 
                disableTypography 
                style={{
                  color: "var(--primary-text)", 
                  position: 'relative',   // Placement of adorments not working with grid
                  top: '-17px',           // Current workaround is with this manual adjustment :\
                  right: '-70%',   
                }}
              />
            }
          />
        </div>
      </div>
    </AttributeContainer>
  );
}

interface GeneralizationProps {
  setParams: (gen: number, genDetails: number) => void;
}

/**
 * Generalization (Macro & Micro) control sliders
 * @param { setParams: callback } 
 */
function GeneralizationControl({ setParams: callback }: GeneralizationProps) {
  const project = React.useContext(ProjectContext);

  const [macro, setMacro] = React.useState(project.settings.generalization);
  const [micro, setMicro] = React.useState(project.settings.generalizationDetails);
  const [isBound, setBound] = React.useState(true);

  // Handle slider changes
  const handleMacroChange = (newMacro: number) => {
    if (isBound) setMicro(newMacro);
    setMacro(newMacro);
  };
  const handleMicroChange = (newMicro: number) => {
    if (isBound) setMacro(newMicro);
    setMicro(newMicro);
  };

  React.useEffect(() => {
    callback(macro, micro)
    project.settings.generalization = macro;
    project.settings.generalizationDetails = micro;
  }, [macro, micro]);

  return (
    <>
      <AttributeContainer
        paramName="Generalization"
        description="Generalization to remove distracting terrain details and 
          adjust granularity of the shading."
      >
        <div className="d-flex flex-column">
          {/* Macro slider */}
          <AttributeInput
            label="Macro"
            value={macro}
            callback={handleMacroChange}
          />
          {/* Give the option to bind sliders together (on by default) */}
          <LinkInputsButton isBound={isBound} callback={setBound} />
          {/* Micro slider */}
          <AttributeInput
            label="Micro"
            value={micro}
            callback={handleMicroChange}
          />
        </div>
      </AttributeContainer>
    </>
  );
}

/**
 * Aerial perspective control slider
 * @param {
 *   setParams: callback,
 * } 
 */
function AerialPerspectiveControl({
  setParams: callback,
}: {
  setParams: (f: number) => void;
}) {
  const project = React.useContext(ProjectContext);
  const [aerial, setAerial] = React.useState(project.settings.aerialPerspective);

  // Handle value change
  const handleChange = (newAerial: number) => setAerial(newAerial);
  React.useEffect(() => {
    callback(aerial);
    project.settings.aerialPerspective = aerial;
  }, [aerial]);

  return (
    <>
      <AttributeContainer
        paramName="Aerial Perspective"
        description="Aerial perspective simulation to emphasize high elevations."
      >
        <AttributeInput callback={handleChange} value={aerial} />
      </AttributeContainer>
    </>
  );
}

interface TerrainTypeProps {
  setParams: (min: number, max: number) => void;
}

/**
 * Terrain type control min-max slider
 * @param {
 *   setParams: callback,
 * }  
 */
function TerrainTypeControl({setParams: callback}: TerrainTypeProps) {
  const [MIN_VALUE, MAX_VALUE] = [0, 100];
  const MIN_DIST = 1;
  const project = React.useContext(ProjectContext);

  const [value, setValue] = React.useState<number[]>([
    project.settings.elevationRangeMin,
    project.settings.elevationRangeMax,
  ]);
  const [low, high] = value;

  // Handle value change
  const handleChange = (
    event: Event | null,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < MIN_DIST) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], MAX_VALUE - MIN_DIST);
        setValue([clamped, clamped + MIN_DIST]);
      } else {
        const clamped = Math.max(newValue[1], MIN_DIST);
        setValue([clamped - MIN_DIST, clamped]);
      }
    } else {
      setValue(newValue as number[]);
    }
  };

  React.useEffect(() => {
    callback(low, high);
    project.settings.elevationRangeMin = low;
    project.settings.elevationRangeMax = high;
  }, [low, high]);

  return (
    <AttributeContainer
      paramName="Terrain Type"
      description="The terrain type shown by the shaded relief image."
    >
      <div className="terrain-type__root" >
        <NumberInput  // Lacks styling of standard text field
          style={{ gridRow: 1, gridColumn: 1 }}
          value={low}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={1}
          onChange={(e: any, value: any) => handleChange(null, [parseInt(value), high], 0)}
        />
        <Slider
          className="terrain-type__slider"
          style={{ gridRow: 1, gridColumn: 2 }}
          value={value}
          onChange={handleChange}
        />
        <NumberInput  // Lacks styling of standard text field
          style={{ gridRow: 1, gridColumn: 3 }}
          value={high}
          min={MIN_VALUE}
          max={MAX_VALUE}
          step={1}
          onChange={(e: any, value: any) => handleChange(null, [low, parseInt(value)], 1)}
        />
        <div 
          className="d-flex justify-content-between"
          style={{ gridRow: 2, gridColumn: 2 }}
        >
          <Icon
            className="terrain-type__terrain-icon clickable"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              createRipple(e, 10);
              setValue([MIN_VALUE, high]);
            }}
          >
            <img src={flatTerrain} alt="flat" />
          </Icon>
          <Icon
            className="terrain-type__terrain-icon clickable"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              createRipple(e, 100);
              setValue([low, MAX_VALUE]);
            }}
          >
            <img src={alpineTerrain} alt="alpine" />
          </Icon>
        </div>
      </div>
    </AttributeContainer>
  );
}

interface FlatAreaProps {
  setParams: (amount: number, size: number) => void;
}

/**
 * Flat area detail effect control sliders
 * @param { setParams } 
 */
function FlatAreaDetailsControl({setParams: callback}: FlatAreaProps) {
  const project = React.useContext(ProjectContext);

  const [isBound, setBound] = React.useState(false);
  const [flatArea, setFlatArea] = React.useState<number[]>([
    project.settings.flatAreaAmount,
    project.settings.flatAreaSize,
  ]);

  const [amount, size] = flatArea;
  const isSizeDisabled = amount === 0;

  // Handle value changes
  const handleAmountChange = (newVal: number) => {
    isBound ? setFlatArea([newVal, newVal]) : setFlatArea([newVal, size]);
  };
  const handleSizeChange = (newVal: number) => {
    isBound ? setFlatArea([newVal, newVal]) : setFlatArea([amount, newVal]);
  };

  React.useEffect(() => {
    callback(amount, size);
    project.settings.flatAreaAmount = amount;
    project.settings.flatAreaSize = size;
  }, [amount, size]);

  return (
    <>
      <AttributeContainer
        paramName="Flat Area Details"
        description={
          <>
            <div>Amount: Emphasize details in flat areas.</div>
            <div>
              Size: Adjust the size of emphasized details in flat areas.
            </div>
          </>
        }
      >
        <div className="d-flex flex-column">
          {/* Amount slider */}
          <AttributeInput
            label="Amount"
            value={amount}
            callback={handleAmountChange}
          />
          <LinkInputsButton isBound={isBound} callback={setBound} />

          {/* Size slider */}
          <AttributeInput
            label="Size"
            value={size}
            callback={handleSizeChange}
            disabled={isSizeDisabled}
          />
        </div>
      </AttributeContainer>
    </>
  );
}

interface LinkInputsButtonProps {
  isBound: boolean;
  callback: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Toggle linked sliders button
 * @param { isBound, callback } 
 */
function LinkInputsButton({ isBound, callback }: LinkInputsButtonProps) {
  const toggleBound = () => callback(!isBound);

  return (
    <LinkIcon
      className={`link-button rotate-link${!isBound ? "-reverse" : ""}`}
      onClick={toggleBound}
    />
  );
}

/**
 * Contrast control slider
 * @param {
 *   setParams: callback,
 * } 
 */
function ContrastControl({
  setParams: callback,
}: {
  setParams: (f: number) => void;
}) {
  const project = React.useContext(ProjectContext);
  const [contrast, setContrast] = React.useState(project.settings.slopeDarkness);

  const handleChange = (value: number) => setContrast(value);

  // Handle value change
  React.useEffect(() => {
    callback(contrast);
    project.settings.slopeDarkness = contrast;
  }, [contrast]);

  return (
    <>
      <AttributeContainer
        paramName="Contrast"
        description="Darkness of shaded slope at high elevations."
      >
        <AttributeInput callback={handleChange} value={contrast} />
      </AttributeContainer>
    </>
  );
}


/**
 * Render settings panel component
 * @param { callback } 
 */
function MapProperties({ callback }: { callback: any }) {
  const project = React.useContext(ProjectContext);
  const [mapParams, setMapParams] = React.useState<ProcessingSettings>(
    project.settings
  );
  
  /**
   * Merge setting values from all sliders
   * @param params 
   */
  const mergeParams = (params: object) =>
    setMapParams((prevState) => {
      return {
        ...prevState,
        ...params,
      };
    });
  
  // Handle change of settings
  React.useEffect(() => {
    project.settings = mapParams;
    callback(mapParams);
  }, [mapParams]);

  return (
    <>
      {/* Display all setting sliders */}
      <NeuralNetworkControl
        setParams={(modelNo) => mergeParams({ modelNo: modelNo })}
      />
      <IluminationControl
        setParams={(angle: number) => mergeParams({ lightRotation: angle })}
      />
      <GeneralizationControl
        setParams={(macro: number, micro: number) =>
          mergeParams({
            generalization: macro,
            generalizationDetails: micro,
          })
        }
      />
      <AerialPerspectiveControl
        setParams={(aerial: number) =>
          mergeParams({ aerialPerspective: aerial })
        }
      />
      <ContrastControl
        setParams={(contrast: number) =>
          mergeParams({ slopeDarkness: contrast })
        }
      />
      <TerrainTypeControl
        setParams={(min: number, max: number) =>
          mergeParams({
            elevationRangeMin: min,
            elevationRangeMax: max,
          })
        }
      />
      <FlatAreaDetailsControl
        setParams={(amount: number, size: number) =>
          mergeParams({
            flatAreaAmount: amount,
            flatAreaSize: size,
          })
        }
      />
    </>
  );
}

export default MapProperties;
