// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Purchase credits page

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import logo from "#assets/logo.png";
import Container from "@mui/material/Container";
import "#styles/pages/PaymentPage";
import {handleStripePurchase} from "#libs/apis/backend";
import {
  useAuthUser,
} from "react-auth-kit";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ProjectBar from "#components/ProjectBar";
import { CreditsContext } from "#components/Contexts";
import { EDIT_MAP_PAGE } from "App";


/**
 * Function to get price of credits in various currencies
 * NOTE: Credits will only be available in USD (18/9/2024)
 * 
 * @param numCredits The amount of credits
 * @param toCurrency Currency identifier string
 * @returns exchange rate
 */
function getExchangeRate(numCredits: number, toCurrency: string): number {
  return numCredits;
  // if (toCurrency === "usd") {
  //   return numCredits * 0.64; // Convert to USD and round to cents
  // } 
  // else if (toCurrency === "eur") {
  //   return numCredits * 0.6; // Convert to EUR and round to cents
  // } 
  // else {
  //   return numCredits;
  // }
}


/**
 * Payment page element
 * @returns  React element
 */
function Payment({ userCredits }: {userCredits: number} ): JSX.Element {
  const DEFAULT_CURRENCY = "usd"

  const [creditAmount, setCreditAmount] = useState(1);
  const [selectedOption, setSelectedOption] = useState<string>(DEFAULT_CURRENCY);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Get user auth key
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";

  // Handler functions for dialogue boxes
  const handleOpenConfirmation = () => {
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationModal(false);
  };

  const handleCreditAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreditAmount(parseInt(event.target.value));
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const navigate = useNavigate();
  const navigateToEditPage = () => navigate(EDIT_MAP_PAGE);

  /**
   * Async function to handle credit purchase
   */
  const handleCreditPurchase = async () => {
    await handleStripePurchase(creditAmount, selectedOption, authKey);
  }

  const convertedPrice = creditAmount ? `$${parseFloat(getExchangeRate(creditAmount, selectedOption).toFixed(2))}` : null;

  return (
    <>
      <Container maxWidth="sm">
        <Button sx={{ 'justify-content': "left" }} onClick={navigateToEditPage} startIcon={<KeyboardBackspaceIcon />}>
            Back
        </Button>

        {/* Title and current credit balance */}
        <h1>Purchase Eduard Credits</h1>
        <hr />
        <b><p>Current credit balance: {userCredits}</p></b>
        <div>Eduard credits allow you to render custom shadings.</div>
        <div>Please enter how many Eduard credits you would like to purchase.</div>
        <img className="eduard-logo" src={logo} alt="Eduard icon" />

        {/* Purchase new credits */}
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="inputContainer">
          <div className="inputBox">
          <input
            type="number"
            value={creditAmount}
            onChange={handleCreditAmountChange}
            min={1}
          />
          <span>Number of Eduard credits</span>
          </div>
            <div className="conversionBox">
              <span>{convertedPrice}</span>
                <select value={selectedOption} onChange={handleOptionChange}>
                  {/* <option value="aud">AUD</option> */}
                  <option value={DEFAULT_CURRENCY}>USD</option>
                  {/* <option value="eur">EUR</option> */}
                </select>

            </div>
          </div>
          <br></br>
          <p>All purchases are non refundable. Click "Checkout" to be securely redirected to Stripe, our trusted payment provider, to complete your transaction.</p>
          <button type="button" onClick={handleOpenConfirmation} className="pay-button">Checkout</button>
        </form>
        {showConfirmationModal && (
          <div className="modal-overlay">
            <div className="popup">
              <p>All purchases are non refundable. Do you want to be redirected to Stripe to complete your purchase securely?</p>
              <div>
                <button className="neutral-button" onClick={handleCloseConfirmation}>Cancel</button>
                <button className="yes-button" onClick={handleCreditPurchase}>Checkout</button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}


/**
 * Payments page
 * @returns React element
 */
function PaymentPage(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const { userCredits, updatePageCredits } = React.useContext(CreditsContext);

  const creditPurchaseModal = (
    <div className="modal-overlay">
      <div className="popup">
        <p>{modalMessage}</p>
        <button className="close-button" onClick={() => setIsModalOpen(false)}>Close</button>
      </div>
    </div>
  );

  // Display purchase success or failure message on page load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('cancel')) {
      setIsModalOpen(true);
      setModalMessage("Purchase was unsuccessful. Please try again.");
    } else if (urlParams.get('success')) {
      updatePageCredits();
      setIsModalOpen(true);
      setModalMessage("Purchase was successful! Thank you for your payment.");
    }
  }, []);

  return (
    <ProjectBar>
      <div className="page-layout">
        {isModalOpen && creditPurchaseModal}
        <Payment userCredits={userCredits} />
      </div>
    </ProjectBar>
  );
}

export default PaymentPage;
