// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project bar element

import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Link } from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import AccountMenu from "./AccountMenu";
import "#styles/components/ProjectBar";
import logo from "#assets/logo.png";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Props } from "#libs/types";
import { Flags } from 'react-feature-flags';
import { SITE_TITLE } from "#libs/constants"
import { CreditsContext, DrawerContext } from "./Contexts";


/**
 * Drop down menu for account options
 * @param userCredits Number of credits the user has
 * @param isAuthenticated Whether the user is authenticated
 */
function AccountDisplay({ userCredits, isAuthenticated }: { userCredits: number, isAuthenticated: boolean }) {
  return (
    <div className="align-self-end d-inline-flex" style={{minWidth: 150, justifyContent: "space-between"}}>
      {/* User credit balance */}
      {isAuthenticated
        ? 
        <div className="d-flex align-items-center mx-4">
          <PublicIcon fontSize="medium" />
          <span style={{fontSize: 18 }}>{userCredits}</span>
        </div>
        :
        <div className="d-flex align-items-center mx-2">
          <span style={{fontSize: 18 }}>Sign in</span>
          <ChevronRightOutlinedIcon fontSize="small" />
        </div>
      }

      {/* Account menu */}
      <section className="mx-1 justify-content-center">
        <AccountMenu />
      </section>
    </div>
  );
}


/**
 * Projects bar
 * @param { children } 
 */
function ProjectBar({ headerContent, children}: { headerContent?: React.ReactNode, children?: React.ReactNode } ) {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  const isAuthenticated = useIsAuthenticated();
  const {userCredits, updatePageCredits} = React.useContext(CreditsContext);

  const [isPanelOpen, setPanel] = React.useState(React.useContext(DrawerContext));
  const toggleDrawer = () => setPanel((showDrawer) => !showDrawer);

  return (
    <>
      <AppBar className="nav">
        <Toolbar variant="dense" className="nav__toolbar glass--dark">
          {/* Project title */}
          <Link
            variant="h6"
            component={RouterLink}
            to="/"
            className="nav__title"
            underline="none"
          >
            <img className="nav__logo" src={logo} alt="Eduard icon" />
            {SITE_TITLE}
          </Link>
          {headerContent}
          {/* Account display */}
          <Flags authorizedFlags={['enableAuthentication']}>
            <AccountDisplay userCredits={userCredits} isAuthenticated={isAuthenticated()} />
          </Flags>
        </Toolbar>
      </AppBar>
      <DrawerContext.Provider value={isPanelOpen}>
        <div className="nav__content">{children}</div>
      </DrawerContext.Provider>
    </>
  );
}

export default ProjectBar;
