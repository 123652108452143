// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Utility functions

/**
 * Based on python range function
 * @param start Inclusive
 * @param stop Exclusive
 * @returns
 */
function range(start: number, stop: number): number[] {
  return Array.from(Array(stop - start).keys()).map((x) => x + start);
}

export function linspace(start: number, stop: number, num: number, endpoint = true) {
  const div = endpoint ? (num - 1) : num;
  const step = (stop - start) / div;
  return Array.from({length: num}, (_, i) => start + step * i);
}

/**
 * Helper function for parsing errors
 * https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
function getErrorMessage(error: unknown) : string {
  if (error instanceof Error) return error.message
  return String(error)
}

export { getErrorMessage };


  /** debounce function, call back after wait time
 * https://mui.com/x/react-date-pickers/lifecycle/#server-interaction 
 * */ 
export function debounce(func: (...arg: any) => void, wait = 500) {
  let timeout: NodeJS.Timeout;
  function debounced(...args: any) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

/**
 * Return a function restricted to the range
 * @param value value to clamp
 * @param min min value of clamp
 * @param max max value of clamp
 * @returns Value clamped between min and max
 */
export function clamp(value: number, min: number, max: number) : number {
  return Math.min(Math.max(value, min), max);
}