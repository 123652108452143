import React from "react";
import {
	Box,
	CircularProgress,
	Typography
} from "@mui/material";


function ProgressStatusBase({ 
	text, 
	isActive = true, 
	children 
}: { 
	text: string, 
	isActive?: boolean, 
	children: React.ReactNode 
}) {
	const [downloadDots, setDownloadDots] = React.useState<number>(1);

	// Dots progress during OpenTopography download
	React.useEffect(() => {
		if (!isActive) return;

		const downloadDotsInterval = setInterval(() => {
			setDownloadDots((prevDots: number) => (prevDots === 3) ? 1 : prevDots + 1);
		}, 1000);

		return () => clearInterval(downloadDotsInterval);
	}, [isActive]);

	return (
		<div style={{
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: 5,
		}}>
			{children}
			<p>{`${text} ${".".repeat(downloadDots)}`}</p>
		</div>
	);
}

/***
 * Indicator for action durning rendering/download
 */
function ProgressStatus({
	text, 
	isActive = true
}: {
	text: string, 
	isActive?: boolean
}) {
	return (
		<ProgressStatusBase text={text} isActive={isActive}>
			<CircularProgress />
		</ProgressStatusBase>
	);
}

/**
 * Circulars progress with label
 * https://mui.com/material-ui/react-progress/
 * @param props 
 * @returns  
 */
function LabelledProgressStatus({ 
	text, 
	value,
	isActive = true 
}: { 
	text: string, 
	value: number,
	isActive?: boolean 
}) {
	return (
		<ProgressStatusBase text={text} isActive={isActive}>
			<Box position="relative" display="inline-flex">
				<CircularProgress variant="determinate" value={value} />
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						children={`${Math.round(value)}%`}
						variant="caption"
						component="div"
						sx={{ color: "var(--text-color)" }}
					/>
				</Box>
			</Box>
		</ProgressStatusBase>
	);
}

export { ProgressStatus, LabelledProgressStatus };