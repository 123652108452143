// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Edit map page

"use client";
import { ConfirmRenderModal, InsufficientFundsModal } from "#components/CreditsModals";
import MapDisplay from "#components/MapDisplay";
import MapProperties from "#components/MapProperties/MapProperties";
import { LabelledProgressStatus, ProgressStatus } from "#components/ProgressStatus";
import ProjectBar from "#components/ProjectBar";
import { ProjectContext , DrawerContext, CreditsContext} from "#components/Contexts";
import { ImageFormat } from "#libs/ImageExports";
import Project from "#libs/Project";
import {
  cancelRequest,
  fetchElevationData,
  getProgress,
  processCachedTiff,
  setProjName,
  spendUserCredit,
  startRequest,
} from "#libs/apis/backend";
import { CLOSED_RENDER_PAYMENT } from "#libs/sessionStorageKeys";
import { ImageRender } from "#libs/types";
import "#styles/glass";
import "#styles/pages/EditMapPage";
import { AddPhotoAlternateOutlined as NewIcon } from "@mui/icons-material";
import CachedIcon from '@mui/icons-material/Cached';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Button,
  CircularProgress,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import JSZip from 'jszip';
import React from "react";
import {
  useAuthUser,
} from "react-auth-kit";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
} from "react-resizable-panels";
import { v4 as uuidv4 } from 'uuid';

/**
 * Confirmation modal
 * @param { isOpen, onConfirm, onCancel } 
 * @returns  
 */
function ResetShadingModal({ isOpen, onConfirm, onCancel }: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="popup">
        <h2>New Shading</h2>
        <p>Are you sure you want to start a new shading?</p>
        <p><b>This will reset your current shading.</b></p>
        <div>
          <button className="neutral-button" onClick={onCancel}>Cancel</button>
          <button className="yes-button" onClick={onConfirm}>New shading</button>
        </div>
      </div>
    </div>
  );
}

/**
 * Error message modal
 * @param { isOpen, errorMessage, onCancel } 
 * @returns  Element
 */
function RenderErrorModal({ isOpen, errorMessage, onCancel }: {
  isOpen: boolean;
  errorMessage: string | null;
  onCancel: () => void;
}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="popup">
        <h2>Render Failed</h2>
        <p>{errorMessage}</p>
        <p>Please try again.</p>
        <div>
          <button className="no-button" onClick={onCancel}>OK</button>
        </div>
      </div>
    </div>
  );
}

/**
 * Drop down menu for available download formats
 */
function DownloadFormatMenu({
  anchorEl, isOpen, handleClose, handleMenuItemClick
}: {
  anchorEl: HTMLElement | null,
  isOpen: boolean,
  handleClose: () => void,
  handleMenuItemClick: (format: ImageFormat) => void,
}) {
  const imageFormatItems: JSX.Element[] = (
    Object.keys(ImageFormat) as Array<ImageFormat>
  ).map((elem: ImageFormat) => (
    <MenuItem onClick={() => handleMenuItemClick(elem)}>
      {ImageFormat[elem as keyof typeof ImageFormat]}
    </MenuItem>
  ));

  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <InputLabel sx={{paddingX: 2, opacity: 0.5}}>Download Shading</InputLabel>
      {imageFormatItems}
    </Menu>
  );
}


/**
 * Edits map page
 * @returns React element
 */
function EditMapPage(): JSX.Element {
  const auth = useAuthUser();
  const authData = auth();
  const authKey = authData?.authKey || "";
  
  const project = React.useContext<Project>(ProjectContext);
  const { userCredits, updatePageCredits } = React.useContext(CreditsContext);
  const isOpen = React.useContext(DrawerContext);

  const [settings, setSettings] = React.useState(project.settings);
  const [hasUpdatedProject, setHasUpdatedProject] = React.useState(false);
  const [renderUrl, setRenderUrl] = React.useState<ImageRender>({
    imageSrc: "",
    imageHash: Date.now(),
    geoTiff: "",
    png: "",
    world: "",
    projection: "",
  });
  const [isDownloading, setDownloading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isRendering, setRendering] = React.useState<boolean>(false);
  const [hasMapData, setHasMapData] = React.useState<boolean>(false);
  const [requestId, setRequestId] = React.useState<string>("");
  const [progress, setProgress] = React.useState<number>(0);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [shadingName, setShadingName] = React.useState<string>(project.projectName);
  
  const refPanel = React.useRef<ImperativePanelHandle>(null);
  const panel = refPanel.current;
  
  const [showResetShadingModal, setShowResetShadingModal] = React.useState<boolean>(false);
  const [showConfirmRenderModal, setShowConfirmRenderModal] = React.useState<boolean>(false);
  const [showInsufficientFunds, setShowInsufficientFunds] = React.useState<boolean>(false);
  const [showRenderErrorModal, setShowRenderErrorModal] = React.useState(false);

  // Checks for updates to project settings
  React.useEffect(() => {
    if (hasMapData && !hasUpdatedProject) {
      setHasUpdatedProject(true);
    }
  }, [settings]);

  if (panel) isOpen ? panel.expand() : panel.collapse();

  /**
   * Function to reset project values
   */
  const handleNewProject = () => {
    setShowResetShadingModal(false);

    console.log("Map updated sucessfully");

    project.reset();
    setSettings(project.settings);
    setRenderUrl({
      imageSrc: "",
      imageHash: Date.now(),
      geoTiff: "",
      png: "",
      world: "",
      projection: "",
    });
    setDownloading(false);
    setAnchorEl(null);
    setRendering(false);
    setHasMapData(false);
    setRequestId("");
    setProgress(0);
    setHasUpdatedProject(false);
  }

  /**
   * Function to bypass data download checks if demo data is selected
   */
  const handleDemoLoaded = () => {
    if (project.isDemo) {
      setHasMapData(true);
      handleRerender();
    }
  };

  /**
   * Function to handle the render image response from the back-end
   * @param response_obj 
   */
  async function handleRenderResponse(response_obj: any) {
    if (response_obj.jpg) {
      // Successfully charge credit before displaying render
      const successPurchase = (project.isDemo) ? true : await spendUserCredit(authKey);
      if (successPurchase) {
        // If the response images and data is valid, update the front-end values and display the image
        const responseJpg = `data:image/jpg;base64,${response_obj.jpg}`;
        const responsePng = `data:image/png;base64,${response_obj.png}`;
        const responseTiff = `data:image/tiff;base64,${response_obj.geoTiff}`;
        const responseWorld = `data:text/plain;base64,${response_obj.world}`;
        const responseProjection = `data:text/plain;base64,${response_obj.projection}`;

        setRenderUrl({
          imageSrc: responseJpg,
          imageHash: Date.now(),
          geoTiff: responseTiff,
          png: responsePng,
          world: responseWorld,
          projection: responseProjection,
        });
      
        updatePageCredits();
        return;
      }
      
    // Otherwise if not valid img or unsuccessful payment, display an error message
    console.error("Setting as error");
    console.error(response_obj);
    setErrorMessage("Failed to process image")
    handleOpenRenderErrorModal();
    setRenderUrl({
      imageSrc: "error",
      imageHash: Date.now(),
      geoTiff: "error",
      png: "error",
      world: "error",
      projection: "error",
      });
    }
  }

  /**
   * Function to extract error message string from response
   * @param xmlString response string
   * @returns  Error message string
   */
  const parseError = (xmlString: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const errorTag = xmlDoc.getElementsByTagName("error")[0];
    return errorTag.textContent || "Unknown error occurred.";
  };

  /**
   * Handle project name updates
   * @param event 
   */
  const handleNameUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Get the text from the input box
    const inputShadingName = event.target.value;
  
    // Update the project name in the database
    const setNameInDB = async (inputShadingName: string) => {
      const newShadingName = (inputShadingName.length > 0) ? inputShadingName : "Untitled map";
      const success = await setProjName(project.mapId, newShadingName);
      if (success) {
        console.log("Name updated")
      } else {
        console.error("Name update failed")
      }
    };

    setShadingName(inputShadingName);
    setNameInDB(inputShadingName)
      .catch(console.error)
  };

  // Call the renderer when the bounds change (first time render)
  React.useEffect(() => {
    if (project.bounds || project.isDemo) {
      setHasMapData(true);
      handleRerender();
    }

    window.addEventListener("dataLoaded", handleDemoLoaded);
  }, []);

  // Check for render progress percentage updates when a render is triggered
  React.useEffect(() => {
    if (!requestId || !isRendering) return;

    // Start a timer to request an update for the progress every 100ms
    const interval = setInterval(async () => {
      try {
        const currentProgress = await getProgress(requestId);
        setProgress(currentProgress);

        // If the render is complete, delete the timer
        if (currentProgress === 100) {
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [requestId, isRendering]);

  /* Callback function once render has completed */
  function onFinishedRender() {
    setRendering(false);
    setHasUpdatedProject(false);
    setProgress(0);
  }

  /**
   * Handler for render requests
   */
  const handleRerender = () => {
    if (project.isDemo && project.settings) {
      // If the demo data is selected, send a render request to the back end with current settings
      const renderDemo = async (p: Project) => {
        const request_id = await startRequest();
        setRequestId(request_id);
        const response_obj = await processCachedTiff(
          "",
          p.settings,
          project.isDemo,
          request_id,
          "Mercator",
          "demo",
          "demo"
        );

        await handleRenderResponse(response_obj);
      };
      setRendering(true);
      renderDemo(project)
        .catch(console.error)
        .finally(onFinishedRender);
      return;
    } 
    
    // Check if sufficient credits 
    if (userCredits <= 0) {
      setShowInsufficientFunds(true);
      return;
    }

    // Confirm with user to render image
    if (sessionStorage.getItem(CLOSED_RENDER_PAYMENT) !== CLOSED_RENDER_PAYMENT) {
      setShowConfirmRenderModal(true);
      return;
    }
    
    // User project render
    if (
      project.hasApiKey() &&
      project.bounds &&
      project.settings &&
      project.elevationModel &&
      project.projection
    ) {
      // If not a demo, check if the data is already cached
      if (project.cached) {
        // Request back-end to render cached data with current settings
        const renderCachedData = async (p: Project) => {
          const request_id = await startRequest();
          setRequestId(request_id);

          console.log("Using cached data for bounds: " + project.cachedFilepath);
          // We have cached data that matches!
          const response_obj = await processCachedTiff(
            project.cachedFilepath,
            p.settings,
            project.isDemo,
            request_id,
            project.projection,
            authKey,
            project.mapId
          );

          await handleRenderResponse(response_obj);
        }
        setRendering(true);
        renderCachedData(project)
          .catch(console.error)
          .finally(onFinishedRender);

      } else {
        // Otherwise if the data is not cahced, download it and render
        const downloadAndRenderData = async (p: Project) => {
          const request_id = await startRequest();
          setRequestId(request_id);

          console.log("Calling Fetch");
          setDownloading(true);

          // First, grab the elevation data so we can cache it then use it for rendering
          const responseData = await fetchElevationData(
            p.apiKey,
            p.bounds,
            project.elevationModel,
            authKey,
            project.projection
          );

          console.log("Finished fetch");
          setDownloading(false);

          // Give error message if the download failed
          if (!responseData.responseOk) {
            const errorMessage = parseError(responseData.responseText);
            setErrorMessage(errorMessage)
            handleOpenRenderErrorModal();
            setRenderUrl({
              imageSrc: "error",
              imageHash: Date.now(),
              geoTiff: "error",
              png: "error",
              world: "error",
              projection: "error",
            });

          } else {
            // If the download succeeded, cache the data
            project.cached = true;
            project.cachedFilepath = responseData.filepath;
            // Use the downloaded data to render an image
            const response_obj = await processCachedTiff(
              responseData.filepath,
              p.settings,
              project.isDemo,
              request_id,
              project.projection,
              authKey,
              responseData.mapId
            );

            await handleRenderResponse(response_obj);
          }
        }
        setRendering(true);
        downloadAndRenderData(project)
          .catch(console.error)
          .finally(onFinishedRender);
      }
    }
  }

  /**
   * Function to handle the download button
   * @param filetype 
   */
  const handleDownload = async (filetype: ImageFormat) => {
    // Determine the output file name from data type
    // If not a demo, use a unique UUID code
    if (project.isDemo) {
      var outputName = "demo";
    } else if (requestId) {
      var outputName = requestId
    } else {
      console.log("Could not find existing request ID, generating new UUID")
      var outputName = uuidv4();
    }

    const zip = new JSZip();

    // Retrieve the image in the selected format
    if (renderUrl) {
      // Handle download of image format.
      let url;
      let filename;
      switch (ImageFormat[filetype as keyof typeof ImageFormat]) {
        case ImageFormat.GEOTIFF: {
          url = renderUrl.geoTiff;
          filename = outputName + ".tif";
          break;
        }
        case ImageFormat.JPEG: {
          url = renderUrl.imageSrc;
          filename = outputName + ".jpg";
          break;
        }
        case ImageFormat.PNG: {
          url = renderUrl.png;
          filename = outputName + ".png";
          break;
        }
        default: {
          console.error(`Invalid image format provided: ${filetype}`)
          return;
        }
      }
      const imageContent = await fetch(url).then(r => r.blob());
      zip.file(filename, imageContent);

      // Also include the geospatial metadata files
      const worldContent = await fetch(renderUrl.world).then(r => r.blob());
      zip.file(outputName + ".tfw", worldContent);

      const projectionContent = await fetch(renderUrl.projection).then(r => r.blob());
      zip.file(outputName + ".prj", projectionContent);

      // Generate a ZIP file and download it to browser
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(zipBlob);
      a.download = outputName + ".zip";
      a.click();
    }
  };

  // Interaction helper functions
  const handleCancel = () => { cancelRequest(requestId); };
  const handleClose = () => { setAnchorEl(null); };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value: ImageFormat) => {
    handleClose();
    handleDownload(value);
  };

  const handleOpenResetShadingModal = () => { setShowResetShadingModal(true); };
  const handleCloseConfirmNewProject = () => { setShowResetShadingModal(false); };

  const handleOpenRenderErrorModal = () => { setShowRenderErrorModal(true); };
  const handleCloseError = () => { setShowRenderErrorModal(false); };


  // Handlers for dialogue boxes
  const handleRender = () => {
    if (userCredits > 0) {
      setShowConfirmRenderModal(true);
    } else {
      setShowInsufficientFunds(true);
    }
  };
  
  const handleConfirmRender = () => {
    setShowConfirmRenderModal(false);
    sessionStorage.setItem(CLOSED_RENDER_PAYMENT, CLOSED_RENDER_PAYMENT);
    handleRerender();
  }

  const handleCloseConfirmRender = () => {
    setShowConfirmRenderModal(false);
  };

  const handleCloseInsufficientFunds = () => {
    setShowInsufficientFunds(false);
  };

  const headerContent = (
    <section className="d-flex justify-content-end px-2" style={{ width: "100%" }}>
      {/* Project name input */}
      {project.mapId &&
        <section className="m-2">
          <TextField
            id="outlined-required"
            className={`shading-title ${shadingName.length <= 0 && "shading-title__label__empty"}`}
            size="small"
            color="primary"
            required
            fullWidth
            label="Shading name"
            defaultValue={project.projectName}
            onChange={handleNameUpdate} />
        </section>}
      {/* New project button */}
      <Tooltip title="Create new shading" placement="bottom-start">
        <Button
          color={"primary"}
          variant="outlined"
          className="icon-button m-2"
          onClick={handleOpenResetShadingModal}
          disabled={renderUrl.imageSrc === "" || isRendering || isDownloading || !renderUrl.imageSrc}
          sx={{
            border: `2px solid`,
            width: "30px",
            height: "30px",
          }}
        >
          {isDownloading ? (
            <CircularProgress size="1em" className="me-1" />
          ) : isRendering ? (
            <CircularProgress size="1em" className="me-1" />
          ) : (
            <NewIcon />
          )}

        </Button>
      </Tooltip>
      {/* Download image button */}
      <Tooltip title="Download image" placement="bottom-start">
        <Button
          color={"primary"}
          variant="outlined"
          className="icon-button m-2"
          onClick={handleClick}
          disabled={renderUrl.imageSrc === "" || renderUrl.imageSrc === "error" || isRendering || isDownloading || !renderUrl.imageSrc}
          sx={{
            border: `2px solid`,
            width: "30px",
            height: "30px",
          }}
        >
          {isDownloading ? (
            <CircularProgress size="1em" className="me-1" />
          ) : isRendering ? (
            <CircularProgress size="1em" className="me-1" />
          ) : (
            <FileDownloadOutlinedIcon />
          )}

        </Button>
      </Tooltip>
      <DownloadFormatMenu
        anchorEl={anchorEl}
        isOpen={open}
        handleClose={handleClose}
        handleMenuItemClick={handleMenuItemClick} />
    </section>
  );

  return (
    <ProjectBar headerContent={headerContent}>
      <PanelGroup
        autoSaveId="conditional"
        direction="horizontal"
        className="background"
      >
        <ConfirmRenderModal 
          isOpen={showConfirmRenderModal}
          onConfirm={handleConfirmRender} 
          onCancel={handleCloseConfirmRender} 
          userCredits={userCredits}      
        />
        <InsufficientFundsModal
          isOpen={showInsufficientFunds}
          onCancel={handleCloseInsufficientFunds} 
          userCredits={userCredits}      
        />
        <ResetShadingModal
          isOpen={showResetShadingModal}
          onConfirm={handleNewProject}
          onCancel={handleCloseConfirmNewProject}
        />
        <RenderErrorModal
          isOpen={showRenderErrorModal}
          errorMessage={errorMessage}
          onCancel={handleCloseError}
        />
        {/* Render image display panel */}
        <Panel
          id="left"
          className="page-content d-flex justify-content-center align-items-center"
          // minSize={80}
          // maxSize={100}
          defaultSize={80}
        >
          {/* Show loading symbols if a download or render is in progress */}
          {isDownloading ? <ProgressStatus text="Downloading" isActive={isDownloading} />
            : isRendering ? <LabelledProgressStatus text="Rendering" value={progress} isActive={isRendering} />
              : (
                <>
                  <MapDisplay renderUrl={renderUrl} />
                  {/* Apply settings button*/}
                  {hasMapData && hasUpdatedProject && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      className="refresh-button"
                      onClick={handleRerender}
                      disabled={!hasMapData || isRendering || isDownloading}
                    >
                      <CachedIcon className="refresh-button__icon" />
                      <span>Refresh</span>
                    </Button>
                  )}
                </>
              )}
        </Panel>

        {/* Right hand side panel */}
        <div className="settings-panel glass--dark page-content"
          style={{
            minWidth: "300px",
            maxWidth: "clamp(300px, 30%, 350px)",
          }}
        >
          <Stack
            spacing={0.5}
            sx={{ whiteSpace: "nowrap" }}
            className="map-panel"
          >
            {/* Setting slider panel */}
            <MapProperties callback={setSettings} />
          </Stack>
        </div>
      </PanelGroup>
    </ProjectBar>
  );
}

export default EditMapPage;
