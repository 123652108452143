// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Render image display element

import React from "react";
import L from "leaflet"
import { IconButton } from "@mui/material";
import {
  UploadFile as UploadFileIcon,
  DownloadForOfflineOutlined as DownloadForOfflineOutlinedIcon,
  ImageOutlined as DemoDataIcon
} from "@mui/icons-material";
import { MapContainer, ImageOverlay } from "react-leaflet";
import { ImageRender } from "#libs/types";
import "leaflet/dist/leaflet.css";
import {
  useIsAuthenticated,
} from "react-auth-kit";

import { ProjectContext } from "#components/Contexts";
import Project from "#libs/Project";
import { Flags } from 'react-feature-flags';
import EduardLogo from "#assets/logo.png";
import "#styles/components/MapDisplay";

const visitDownload = () => (window.location.href = "/download-map");

/**
 * Dropzone upload (Unused)
 */
function DropzoneUpload() {
  return (
    <div className="d-flex flex-column text-center dropzone">
      <div className="d-flex flex-row">
        <div className="d-flex flex-column m-3 me-5">
          <IconButton className="dropzone" sx={{ borderRadius: 2 }}>
            <UploadFileIcon />
          </IconButton>
          Import Grid File
        </div>
        <div className="d-flex flex-column m-3 ms-5" onClick={visitDownload}>
          <IconButton className="dropzone" sx={{ borderRadius: 2 }}>
            <DownloadForOfflineOutlinedIcon />
          </IconButton>
          Download Grid
        </div>
      </div>
      Or drop a grid file here
    </div>
  );
}

/**
 * Handle load demo button
 * @param project Project context
 */
function loadDemo(project: Project) {
  // Set the isDemo flag
  project.isDemo = true;
  // Trigger a demo loaded event to render it
  const event = new Event('dataLoaded');
  window.dispatchEvent(event);
}

/**
 * New project option buttons when the user is logged in
 */
function DefaultUpload() {
  const project = React.useContext<Project>(ProjectContext);

  return (
    <div className="d-flex flex-column text-center dropzone">
      <div className="d-flex flex-row">
        {/* Download new map button */}
        <div className="d-flex flex-column m-3 ms-5" onClick={visitDownload}>
          <IconButton className="dropzone" sx={{ borderRadius: 2 }}>
            <DownloadForOfflineOutlinedIcon sx={{fontSize: 40}} />
          </IconButton>
          Download Grid
        </div>
        {/* Load demo data button */}
        <div className="d-flex flex-column m-3 me-5" onClick={() => loadDemo(project)}>
          <IconButton className="dropzone" sx={{ borderRadius: 2}}>
            <DemoDataIcon sx={{fontSize: 40}} />
          </IconButton>
          Try Demo Data
        </div>
      </div>
    </div>
  );
}

/**
 * New project options when the user is not logged in (only demo available)
 */
function DemoUpload() {
  const project = React.useContext<Project>(ProjectContext);

  return (
    <div className="d-flex flex-column text-center dropzone">
      Log in to create beautiful shaded relief images for your maps
      {/* Load demo data button */}
      <div className="d-flex flex-row justify-content-center">
        <div className="d-flex flex-column m-3 me-5 text-center" onClick={() => loadDemo(project)}>
          <IconButton className="dropzone" sx={{ borderRadius: 2 }}>
            <DemoDataIcon />
          </IconButton>
          Try demo data
        </div>
      </div>
    </div>
  );
}

/**
 * Element for displaying new project option dropzone
 */
function UploadMap() {
  const onDrop = React.useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("File reading was aborted");
      reader.onerror = () => console.log("File reading has failed");
      reader.onload = () => {
        const binStr = reader.result;
        console.log(binStr);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const isAuthenticated = useIsAuthenticated();

  return (
    <div>
      <Flags authorizedFlags={['enableAuthentication']}
        renderOn={() => isAuthenticated() ? <DefaultUpload /> : <DemoUpload />}
        renderOff={() => <DefaultUpload />}
      />
    </div>
  );
}

/**
 * Zoomable image display of render using Leaflet map.
 * @param image Image src url and hash time. 
 */
function ImageZoom({ image }: {image: ImageRender}) {
  const PADDING = 0.5;

  const [bounds, setBounds] = React.useState<L.LatLngBounds>(
                                new L.LatLngBounds([[-90,-180],[90,180]]));
  const imgObj = new Image();
  imgObj.src = image.imageSrc;

  React.useEffect(() => {
    const aspect = imgObj.width / imgObj.height || 1;
    setBounds(new L.LatLngBounds([[-90,-180*aspect],[90,180*aspect]]));
  }, [imgObj.width, imgObj.height]);

  return (
    <>    
      <MapContainer
        id="map"
        className="image-zoom-container"
        center={[0, 0]}
        zoom={1}
        minZoom={0}
        maxZoom={3}
        maxBounds={bounds.pad(PADDING)}
        maxBoundsViscosity={0.5}
        attributionControl={false}
      >
        <ImageOverlay 
          key={image.imageHash}
          url={image.imageSrc}
          bounds={bounds}
          errorOverlayUrl={EduardLogo}
        />
      </MapContainer>
    </>
  );
}


/**
 * Map display providing either a dropzone or a zoomable image.
 * @param renderUrl url of image to display
 */
function MapDisplay({ renderUrl }: { renderUrl?: ImageRender }) {
  return (
    <>
      {renderUrl && renderUrl.imageSrc.length > 0 
        ? <ImageZoom image={renderUrl} />
        : <UploadMap />
      }
    </>
  );
}

export default MapDisplay;
