// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Project context

import React from 'react';
import Project from '#libs/Project';

const ProjectContext = React.createContext<Project>(new Project());
const DrawerContext = React.createContext(true);

/**
 * Manages and updates credits count throughout app
 */
type CreditsContextType = {
  userCredits: number;
  updatePageCredits: () => Promise<void>;
}
const CreditsContext = React.createContext<CreditsContextType>({
  userCredits: 0,
  updatePageCredits: function (): Promise<void> {
    throw new Error("Function not implemented.");
  }
});


export { ProjectContext, DrawerContext, CreditsContext };
export type { CreditsContextType }