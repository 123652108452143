import { useNavigate } from "react-router-dom";

const CREDITS_PAGE = "/credits";

/**
 * Confirmation modal
 * @param { isOpen, onConfirm, onCancel } 
 */
function ConfirmRenderModal({ isOpen, onConfirm, onCancel, userCredits }: {
	isOpen: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	userCredits: number;
}) {
	if (!isOpen) return null;

	return (
		<div className="modal-overlay">
			<div className="popup">
				<h2>Confirm Render</h2>
				<p style={{textAlign: 'left'}}>Rendering this image will cost 1 Eduard Credit every render.</p>
				<p>Your remaining balance will be: {userCredits - 1}</p>
				<div>
					<button className="no-button" onClick={onCancel}>Cancel</button>
					<button className="yes-button" onClick={onConfirm}>Download</button>
				</div>
			</div>
		</div>
	);
}

/**
 * Insufficients funds modal
 * @param { isOpen, onCancel } 
 */
function InsufficientFundsModal({ isOpen, onCancel, userCredits }: {
	isOpen: boolean;
	onCancel: () => void;
	userCredits: number
}) {
	const navigate = useNavigate();
	const handlePurchaseCredits = () => navigate(CREDITS_PAGE);
	
	if (!isOpen) return null;

	return (
		<div className="modal-overlay">
			<div className="popup">
				<h2>Insufficient Credits</h2>
				<p>Your current balance is {userCredits} credits.</p>
				<p>You need at least 1 credit to render an image.</p>
				<div>
					<button className="neutral-button" onClick={onCancel}>Cancel</button>
					<button className="yes-button" onClick={handlePurchaseCredits}>Purchase credits</button>
				</div>
			</div>
		</div>
	);
}

export { ConfirmRenderModal, InsufficientFundsModal };